//查看合同
<template>
  <div v-if="loading" class="mainContainer">
    <div v-if="fileLists.length > 1">
      <van-tabs line-height="0" @click="tabChange">
        <!-- <van-icon name="arrow-left" /> -->
        <van-tab
          v-for="(item, index) in fileLists"
          :title="item.name"
          :key="index"
          title-class="line"
        >
          <div v-html="item.file"></div>
        </van-tab>
        <!-- <van-icon name="arrow" /> -->
      </van-tabs>
    </div>
    <div v-if="fileLists.length == 1">
      <div v-html="fileLists[0].file"></div>
    </div>
    <div class="btn">
      <van-button round block type="info" @click="agree" :disabled="btnDisable"
        ><span v-if="lessTime > 0" class="timeColor">({{ lessTime }}s)</span
        >我已同意并阅读本协议</van-button
      >
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Tab, Tabs, Button, Icon } from 'vant'
import { mapState } from 'vuex'

Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Button)
Vue.use(Icon)
import { previewContractApi } from '@/apis/borrow'

export default {
  data() {
    return {
      fileLists: [],
      contractList: [],
      loading: false,
      type: '', // 合同类型
      businessNo: '',
      timeArr: [],
      lessTime: 0,
      timer: null,
      btnDisable: true,
      fromPath: this.$route.query.pagePath,
    }
  },
  created() {
    this.type = this.$route.query.type
    this.contractList = JSON.parse(this.$route.query.file) || []
    if (this.type == 'login') {
      this.contractList.forEach(item => {
        item.tempCode = item.dictValue
      })
    } else if (this.type == 'index') {
      let fileJson = {
        tempCode: this.contractList.code,
        displayName: this.contractList.name,
      }
      this.contractList = []
      this.contractList.push(fileJson)
    }
    if (this.type == 'credit') {
      this.businessNo = this.creditApplySeqNo
    } else if (this.type == 'borrow') {
      this.businessNo = this.loanNo
    } else {
      this.businessNo = null
    }
    // 需要强制阅读
    if (this.fromPath) {
      this.setForceTime()
    } else {
      this.btnDisable = false
    }
    this.previewContract()
  },
  computed: {
    ...mapState({
      loanNo: state => state.borrow.loanNo,
      creditApplySeqNo: state => state.borrow.creditApplySeqNo,
    }),
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    // 强制阅读时间
    setForceTime() {
      // 强制阅读时间
      let timeArr = []
      timeArr = this.contractList.filter(v => {
        return v.forceReadTime > 0
      })
      if (timeArr.length > 0) {
        timeArr.forEach(item => {
          this.timeArr.push(item.forceReadTime)
        })
        this.lessTime = Math.min(...this.timeArr)
      }
      this.timer = setInterval(() => {
        this.lessTime--
        if (this.lessTime <= 0) {
          clearInterval(this.timer)
          this.btnDisable = false
        }
      }, 1000)
    },
    // 授信合同
    previewContract() {
      this.fileLists = []
      if (this.contractList.length > 0) {
        this.contractList.forEach(item => {
          let data = {}
          // 是否有业务类型
          if (!this.businessNo) {
            // 是否有版本号
            if (item.version) {
              data = {
                params: item.tempCode + '/' + item.version,
              }
            } else {
              data = {
                params: item.tempCode,
              }
            }
          } else {
            if (item.version) {
              data = {
                params: item.tempCode + '/' + item.version + '/' + this.businessNo,
              }
            } else {
              data = {
                params: item.tempCode + '/' + this.businessNo,
              }
            }
          }
          previewContractApi(data).then(res => {
            if (res.indexOf('未查询到合同模板信息') == -1) {
              this.fileLists.push({
                name: item.displayName,
                file: res,
              })
            }
            this.loading = true
          })
        })
      }
    },
    // 返回
    agree() {
      if (this.fromPath) {
        this.$router.push({
          name: this.fromPath,
          params: {
            isReadFinish: true,
          },
        })
      } else {
        history.go(-1)
      }
    },
    tabChange(name, title) {
      // alert(name, title)
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .van-tabs__line {
  border-bottom: 2px solid #4d7bfe;
}
.btn {
  position: fixed;
  width: 94%;
  left: 3%;
  bottom: 0.4rem;
  .timeColor {
    color: #1989fa;
    font-weight: 700;
  }
}
</style>
